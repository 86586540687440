import Vue from "vue";
import firebase from "firebase/app";
import VueRouter from "vue-router";
// import store from '@/store/store.js';
//views:
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import AdminDashboard from "@/components/admin/Dashboard.vue";
// import C from "@/constants"

Vue.use(VueRouter);

const routes = [
  {
    name: "Login",
    path: "/login/:type?",
    component: Login,
  },
  {
    name: "Register",
    path: "/register",
    /*
          TODO: lazy load all routes and maybe more? see best practises!
    */
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: () => import("@/views/Register.vue"),
  },
  {
    name: "User",
    path: "/user",
    component: () => import("@/views/HomeLayout.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        name: "Dashboard",
        path: "",
        component: () => import("@/components/Dashboard"),
      },
      {
        name: "Account",
        path: "account",
        component: () => import("@/components/user/Account"),
      },
      {
        name: "Subscription",
        path: "subscription",
        component: () => import("@/components/cards/Subscription"),
      },
      {
        name: "NoSubscription",
        path: "no-subscription",
        component: () => import("@/components/cards/NoSubscription"),
      },
      {
        name: "Password",
        path: "password",
        component: () => import("@/components/user/Security"),
      },
      {
        name: "Download",
        path: "download",
        component: () => import("@/components/user/Download"),
      },
      {
        name: "Invoices",
        path: "invoices",
        component: () => import("@/components/user/Invoices"),
      },
    ],
  },
  {
    name: "Buy",
    path: "/buy",
    component: Home,
    // meta: {
    //   requiresAuth: true,
    // },

    children: [
      {
        name: "DashboardSingle",
        path: "dashboards",
        component: () => import("@/components/DashboardSingle"),
      },
      {
        name: "Checkout",
        path: "checkout",
        component: () => import("@/components/Checkout"),
      },
      {
        name: "Plans",
        path: "plans/:type?",
        component: () => import("@/components/buy/Plans"),
      },
      {
        name: "BillingCycles",
        path: "cycles/:product?",
        component: () => import("@/components/buy/BillingCycles"),
        params: true,
      },
      {
        name: "CreateAccount",
        path: "account",
        component: () => import("@/components/buy/CreateAccount"),
      },
      {
        name: "Payment",
        path: "payment",
        component: () => import("@/components/buy/Payment"),
      },
      // {
      //   name: "Cancel",
      //   path: "cancel",
      //   component: () => import("@/components/buy/Cancel"),
      // },
      {
        name: "Return",
        path: "return",
        component: () => import("@/components/buy/Return"),
      },
    ],
  },
  {
    name: "Support",
    path: "/support",
    component: Home,
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        name: "SupportDashboard",
        path: "",
        component: () => import("@/components/support/SupportDashboard"),
      },
    ],
  },
  {
    name: "Blog",
    path: "/blog",
    component: () => import("@/views/Blog.vue"),
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        name: "ArticlesList",
        path: "",
        component: () => import("@/components/blog/ArticlesList"),
      },
      {
        name: "AddEditArticle",
        path: "article/:mode?",
        component: () => import("@/components/blog/AddEditArticle"),
      },
      {
        name: "CategoriesList",
        path: "categories",
        component: () => import("@/components/blog/CategoriesList"),
      },
      {
        name: "DraftArticlesList",
        path: "drafts",
        component: () => import("@/components/blog/DraftArticlesList"),
      },
      {
        name: "FeaturedArticlesList",
        path: "featured",
        component: () => import("@/components/blog/FeaturedArticlesList"),
      },
    ],
  },
  {
    name: "Admin",
    path: "/admin",
    component: Home,
    meta: {
      requiresAuth: true,
    },

    children: [
      {
        name: "AdminDashboard",
        path: "",
        component: AdminDashboard,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// make sure only logged-in users have access to the content while onyl guests(+not signed-in users) see register & login
router.beforeEach((to, from, next) => {
  console.log("NEXT: ");
  console.log(to);
  console.log("FROM: ");
  console.log(from);
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  // const isAdmin = store.getters.isAdmin;
  // const requiresAdminAuth = to.matched.some((record) => record.meta.requiresAdminAuth);
  // console.log("User is Admin: ", isAdmin);


  if (to.fullPath === "/") {
    next("admin");
    return;
  } else if (requiresAuth && !currentUser) next(Login);
  //else if (requiresAdminAuth && !isAdmin) next(Login);
  else if (!requiresAuth && currentUser) next("admin");
  else next();
});

export default router;
