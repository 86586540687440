export default {
  // Store modes(/states)
  MODE: {
    DEFAULT: 0,
    BUY: 1,
    LOADING: 2,
    ERROR: 3,
  },

  // Database collections
  COLLECTION: {
    USER: "users",
    INFRA: "infrastructures",
    IDENTITES: "identites",
    //RENS_BAIL: 'renseignement_bail',
    //RENS_AGENCE: 'renseignement_agence',
    SYNDICS: "syndics",
    AGENCES: "agences",
    CONTRATS: "contrats",
    RENSEIGNEMENTS: "renseignements",
    HISTORIQUE: "historique",
  },

  //TODO : maybe use router names
  PAGE: {
    HOME: "Welcome",
  },

  // Database fields
  FIELD: {
    // --- INFRASTRUCTURES ---

    // INFORMATIONS GENERALES

    // informations
    //IDBIEN: 'idBien',
    TYPE: "type",
    NATURE: "nature",
    AGE: "age",
    COMMERCIAL: "commercial",
    BUREAU: "bureau",
    PROJET: "projet",
    RESIDENCE: "residence",
    DATE: "date",
    DISPO: "dispo",
    ANNEE: "anneeConstruction",
    NOLOT: "noLot",

    // ! Should be read-only and modify in coordonée (maybe they are missing? 28.1.2020)
    ADRESSE: "adresse",
    ADRESSE2: "adresse2",
  },

  // Select-arrays:
  IDENTITE_TYPE: [
    "Prospect",
    "Bailleur",
    "Preneur",
    "Locataire",
    "Garant",
    "Employé",
  ],

  // types of events
  TYPES_HYST: {
    CREATION_INFRA: "creation_infra",
    MODIFICATION_INFRA: "modification_infra",
    CREATION_IDENTITE: "creation_identite",
    MODIFICATION_IDENTITE: "modification_identite",
    CREATION_SYNDIC: "creation_syndic",
    MODIFICATION_SYNDIC: "modification_syndic",
    CREATION_CONTRAT: "creation_contrat",
    MODIFICATION_CONTRAT: "modification_contrat",
    MODIFICATION_AGENCE: "modification_agence",
  },

  INDEXES: {
    PROSPECT: 0,
    BAILLEUR: 1,
    PRENEUR: 2,
    LOCATAIRE: 3,
    GARANT: 4,
    EMPLOYE: 5,
  },

  // Countries french
  COUNTRIES_FR: [
    "Afghanistan",
    "Afrique Centrale",
    "Afrique du Sud",
    "Albanie",
    "Algérie",
    "Allemagne",
    "Andorre",
    "Angola",
    "Anguilla",
    "Arabie Saoudite",
    "Argentine",
    "Arménie",
    "Australie",
    "Autriche",
    "Azerbaidjan",
    "Bahamas",
    "Bangladesh",
    "Barbade",
    "Bahrein",
    "Belgique",
    "Belize",
    "Bénin",
    "Bermudes",
    "Biélorussie",
    "Bolivie",
    "Botswana",
    "Bhoutan",
    "Boznie-Hérzegovine",
    "Brésil",
    "Brunei",
    "Bulgarie",
    "Burkina Faso",
    "Burundi",
    "Caiman",
    "Cambodge",
    "Cameroun",
    "Canada",
    "Canaries",
    "Cap-Vert",
    "Chili",
    "Chine",
    "Chypre",
    "Colombie",
    "Comores",
    "Congo",
    "Congo démocratique",
    "Cook",
    "Corée du Nord",
    "Corée du Sud",
    "Costa Rica",
    "Côte d'Ivoire",
    "Croatie",
    "Cuba",
    "Danemark",
    "Djibouti",
    "Dominique",
    "Egypte",
    "Emirates Arabes Unis",
    "Equateur",
    "Erythrée",
    "Espagne",
    "Estonie",
    "Etats Unis",
    "Ethiopie",
    "Falkland",
    "Feroe",
    "Fidji",
    "Finlande",
    "France",
    "Gabon",
    "Gambie",
    "Géorgie",
    "Ghana",
    "Gibraltar",
    "Grèce",
    "Grenade",
    "Groenland",
    "Guadeloupe",
    "Guam",
    "Guatemal",
    "Guernesey",
    "Guinée",
    "Guinée-Bissau",
    "Guinée-Equatoriale",
    "Guyana",
    "Guyane Francaise",
    "Haiti",
    "Hawaii",
    "Honduras",
    "Hong-Kong",
    "Hongrie",
    "Inde",
    "Indonésie",
    "Iran",
    "Iraq",
    "Irlande",
    "Islande",
    "Israel",
    "Italie",
    "Jamaique",
    "Jan Mayen",
    "Japon",
    "Jersey",
    "Jordanie",
    "Kazakhstan",
    "Kenya",
    "Kirghizistan",
    "Kiribati",
    "Koweit",
    "Laos",
    "Lesotho",
    "Lettonie",
    "Liban",
    "Liberia",
    "Liechtenstein",
    "Lituanie",
    "Luxembourg",
    "Lybie",
    "Macao",
    "Macédoine",
    "Madagascar",
    "Madère",
    "Malaisie",
    "Malawi",
    "Maldives",
    "Mali",
    "Malte",
    "Man",
    "Mariannes du Nord",
    "Maroc",
    "Marshall",
    "Martinique",
    "Maurice",
    "Mauritanie",
    "Mayotte",
    "Mexique",
    "Micronésie",
    "Midway",
    "Moldavie",
    "Monaco",
    "Mongolie",
    "Montserrat",
    "Mozambique",
    "Namibie",
    "Nauru",
    "Népal",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk",
    "Norvège",
    "Nouvelle Calédonie",
    "Nouvelle Zélande",
    "Oman",
    "Ouganda",
    "Ouzbekistan",
    "Pakistan",
    "Palau",
    "Paléstine",
    "Panama",
    "Papouasie Nouvelle Guinée",
    "Paraguay",
    "Pays-Bas",
    "Pérou",
    "Philippines",
    "Pologne",
    "Polynesie",
    "Puerto Rico",
    "Portugal",
    "Qatar",
    "République Dominicaine",
    "République Tchèque",
    "Réunion",
    "Roumanie",
    "Royaume-Uni",
    "Russie",
    "Rwanda",
    "Sahara Occidental",
    "Sainte Lucie",
    "Saint Marin",
    "Salomon",
    "Salvador",
    "Samoa Occidentale",
    "Samoa Americaine",
    "Sao Tome et Principe",
    "Sénégal",
    "Seychelles",
    "Sierra Leone",
    "Singapour",
    "Slovaquie",
    "Slovéni",
    "Somalie",
    "Soudan",
    "Sri Lanka",
    "Suède",
    "Suisse",
    "Surinam",
    "Swaziland",
    "Syrie",
    "Tadjikistan",
    "Taiwan",
    "Tonga",
    "Tanzanie",
    "Tchad",
    "Thailande",
    "Tibet",
    "Timor Oriental",
    "Togo",
    "Trinité et Tobago",
    "Tunisie",
    "Turménistan",
    "Turquie",
    "Ukraine",
    "Uruguay",
    "Vanuatu",
    "Vatican",
    "Vénézuéla",
    "Vierges Amèricaines",
    "Vierges Britanniques",
    "Vietnam",
    "Wake",
    "Wallis et Futuma",
    "Yémen",
    "Yougoslavie",
    "Zambie",
    "Zimbabwe",
  ],

  COUNTRIES: [
    "Afghanistan",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas (the)",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia (Plurinational State of)",
    "Bonaire, Sint Eustatius and Saba",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory (the)",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cayman Islands (the)",
    "Central African Republic (the)",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands (the)",
    "Colombia",
    "Comoros (the)",
    "Congo (the Democratic Republic of the)",
    "Congo (the)",
    "Cook Islands (the)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Côte d'Ivoire",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic (the)",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands (the) [Malvinas]",
    "Faroe Islands (the)",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories (the)",
    "Gabon",
    "Gambia (the)",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and McDonald Islands",
    "Holy See (the)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran (Islamic Republic of)",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea (the Democratic People's Republic of)",
    "Korea (the Republic of)",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic (the)",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands (the)",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia (Federated States of)",
    "Moldova (the Republic of)",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands (the)",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger (the)",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands (the)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine, State of",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines (the)",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Republic of North Macedonia",
    "Romania",
    "Russian Federation (the)",
    "Rwanda",
    "Réunion",
    "Saint Barthélemy",
    "Saint Helena, Ascension and Tristan da Cunha",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten (Dutch part)",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan (the)",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands (the)",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates (the)",
    "United Kingdom of Great Britain and Northern Ireland (the)",
    "United States Minor Outlying Islands (the)",
    "United States of America (the)",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela (Bolivarian Republic of)",
    "Viet Nam",
    "Virgin Islands (British)",
    "Virgin Islands (U.S.)",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
    "Åland Islands",
  ],


  isInt: function(value) {
    return (
      !isNaN(value) &&
      parseInt(Number(value)) == value &&
      !isNaN(parseInt(value, 10))
    );
  },
};
