<template>
  <footer>
    <div class="footer-logo">
      <img src="@/assets/logos/talkii-logo-white.png" alt="Talkii logo" />
    </div>
    <div class="footer-top">
      <div class="contact">
        <div class="contact-text">CONTACT US</div>
        <div class="footer-box footer-box-duo">
          <div class="footer-box-ic">
            <img src="@/assets/icons/location.svg" alt="" />
          </div>
          <div class="footer-box-text">
            <p>
              SOVI Solutions SARL<br />
              59 Boulevard Royal<br />
              L-2449 LUXEMBOURG<br />
            </p>
          </div>
        </div>
        <div class="footer-box footer-box-duo">
          <div class="footer-box-ic">
            <img src="@/assets/icons/phone.svg" alt="" />
          </div>
          <div class="footer-box-text">
            <p>+352 27 75 83 01</p>
          </div>
        </div>
        <div class="footer-box footer-box-duo">
          <div class="footer-box-ic">
            <img src="@/assets/icons/mail.svg" alt="" />
          </div>
          <div class="footer-box-text">
            <p>
              <a href="mailto:sales@talkii.app">sales@talkii.app</a><br />
              <a href="mailto:support@talkii.app">support@talkii.app</a>
            </p>
          </div>
        </div>
      </div>
      <div class="follow">
        <div class="text-center">FOLLOW US</div>
        <div class="footer-social">
          <a href="https://www.facebook.com/talkiiaac/"
            ><img src="@/assets/icons/fb.svg" alt=""
          /></a>
          <a href="https://www.linkedin.com/company/sovisolutions"
            ><img src="@/assets/icons/li.svg" alt=""
          /></a>
          <a href="https://www.instagram.com/talkii_aac/"
            ><img src="@/assets/icons/ig.svg" alt=""
          /></a>
        </div>
      </div>
      <div class="more">
        <div class="more-text">FIND OUT MORE</div>
        <div class="footer-box">
          <p>
            <a href="https://talkii.app/">Home</a><br />
            <a href="https://talkii.app/features">Features</a><br />
            <a href="https://talkii.app/pricing">Pricing</a><br />
            <a href="https://talkii.app/package">Order the Package</a><br />
            <a href="https://talkii.app/contact">Contact</a><br />
            <a href="https://talkii.app/privacy">Privacy</a>
          </p>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p>
        &copy; 2020 Talkii by Sovi | Webdesign by
        <a href="https://jymbe.com">Jymbe</a>
      </p>
    </div>
  </footer>
</template>

<script>
import firebase from "firebase/app";
import C from "@/constants";

export default {
  methods: {
    goHome() {
      //the default case
      console.log("getMode = default");
      if (this.$store.getters.getMode == C.MODE.DEFAULT) {
        console.log("history cleared");
        this.$store.commit("clearHistory");
        console.log("router-link to welcome");
        this.$router.push({ name: "Welcome" });
        console.log("router-link to welcome should be done");
        return;
      }

      //otherwise check if there might be data loss
      //TODO: differentiate between the different modes
      console.log("getMode != default");
      if (
        this.$store.getters.getMode != C.MODE.DEFAULT &&
        confirm("Etes-vous sûr? Tous les données seront perdues.")
      ) {
        this.$store.commit("finishProcess");
        this.$store.commit("clearHistory");
        this.$router.push({ name: "Welcome" });
      }
    },
    logout() {
      console.log("logout");
      // if ((this.$store.getters.getMode != C.MODE.DEFAULT) && confirm('Etes-vous sûr? Tous les données seront perdues.') ) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("finishProcess");
          this.$router.replace("/login");
        });
      //}
    },
  },
};
</script>

<style scoped></style>
