<template>
  <div>
    <!-- showcase -->
    <div class="showcase-xxs">
      <h4>Admin Dashboard</h4>
      <!-- tabs for app products -->
    </div>
    <div class="wrapper">
      <!-- section -->
      <section
        class="section dashboard jpadding section-unfixed"
        style="background: #1193f5"
      >
        <!-- <router-link :to="{ name: 'ArticlesList' }" class="jcard jcard-dash">
          <h4><i class="fad fa-browser"></i></h4>
          <h4>Web</h4>
          <small>Manage Talkii website and blog.</small>
        </router-link> -->
                <router-link :to="{ name: 'ArticlesList' }" class="jcard jcard-dash">
          <h4><i class="fad fa-typewriter"></i></h4>
          <h4>Blog</h4>
          <small>Manage Talkii blog.</small>
        </router-link>

        <div class="jcard jcard-dash">
          <h4><i class="fad fa-mobile-android"></i></h4>
          <h4>App</h4>
          <small>Manage Talkii app and users.</small>
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
// import C from "@/constants";
// import PLANS from "@/plans";

export default {
  name: "BillingCycles",
  data() {
    return {};
  },
  methods: {
    init() {},
  },

  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/plans.scss";
</style>
