import axios from "axios";
import C from "@/constants";

const state = {
  mode: "LOADING",
  //is dashboard mode
  isDash: false,
  //used for loading
  status: "LOADING",
  history: [],
  //object using during purchase
  buy: {},
  isPackage: false,
  document: {},

  //user data
  userDoc: {},
  userLabel: "",
  platformUser: {},
  subscription: {},
};
const getters = {
  getMode: (state) => {
    console.log("mode: " + state.mode);
    return state.mode;
  },
  /* goBack: state => {
		console.log('pop');
		return state.history.pop()
	}, */
  getHistory: (state) => {
    console.log("history");
    return state.history;
  },
  getIsDash: (state) => {
    return state.isDash;
  },
  getDocument: (state) => {
    console.log("doc");
    return state.document;
  },

  getUserLabel: (state) => {
    return state.userLabel;
  },

  getUser: (state) => {
    console.log("store GET userDoc");
    return state.userDoc;
  },

  getPlatformUser: (state) => {
    console.log("store GET platformUser");
    return state.platformUser;
  },

  getBuy: (state) => {
    console.log("store GET buy");
    return state.buy;
  },
  getIsPackage: (state) => {
    console.log("isPackage: " + state.isPackage);
    return state.isPackage;
  },
  getSubscription: (state) => {
    console.log("store GET subscription");
    return state.subscription;
  },
  isAdmin: (state) => {
    return state.userDoc.admin;
  },

  //Steps
  getBiens: (state) => {
    return state.biens;
  },
};
const actions = {
  //get platform user with id from db than fetch subscription with agreement id from user
  getPlatformUserAndSubscription({ dispatch, commit }, id) {
    axios
      .post("/api/functions.php", {
        request: "get_platform_user",
        userId: id,
      })
      .then(function(response) {
        if (response.data != "") {
          commit("setPlatformUser", response.data);
          console.log(response.data);
          dispatch("getSubscription", response.data.agreementId);
        } else {
          console.log("axios GET subscription: Empty response.");
        }
      })
      .catch(function(error) {
        console.log(error);
        commit("setErrorMode");
      });
  },

  //get subscription with id from db
  getSubscription({ commit }, id) {
    axios
      .post("/api/functions.php", {
        request: "get_subscription",
        agreementId: id,
      })
      .then(function(response) {
        if (response.data != "") {
          commit("setSubscription", response.data);
          console.log(response.data);
          commit("setDisplayMode");
        } else {
          console.log("axios GET subscription: Empty response.");
        }
      })
      .catch(function(error) {
        console.log(error);
        commit("setErrorMode");
      });
  },

  //get platform user data with id from db
  getPlatformUser({ commit }, id) {
    axios
      .post("/api/functions.php", {
        request: "get_platform_user",
        userId: id,
      })
      .then(function(response) {
        if (response.data != "") {
          commit("setPlatformUser", response.data);
          console.log(response.data);
        } else {
          console.log("axios GET subscription: Empty response.");
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
};
const mutations = {
  setDisplayMode(state) {
    console.log("store SET mode DISPLAY");
    state.mode = "DISPLAY";
  },
  setLoadingMode(state) {
    console.log("store SET mode LOADING");
    state.mode = "LOADING";
  },
  setErrorMode(state) {
    console.log("store SET mode ERROR");
    state.mode = "ERROR";
  },
  setMode(state, mode) {
    state.mode = mode;
  },
  setIsDash(state, mode) {
    console.log("store SET isDash");
    state.isDash = mode;
  },
  setDoc(state, doc) {
    state.document = doc;
  },

  //user and subscription data
  setUser(state, userDoc) {
    console.log("store SET userDoc");
    state.userLabel = userDoc.name.charAt(0);
    state.userDoc = userDoc;
  },
  setPlatformUser(state, userDoc) {
    console.log("store SET platformUser");
    state.platformUser = userDoc;
  },
  setSubscription(state, doc) {
    console.log("store SET subscription");
    state.subscription = doc;
  },

  //online story
  setBuy(state, buy) {
    state.buy = buy;
  },
  setIsPackage(state, isPackage) {
    state.isPackage = isPackage;
  },

  //history
  addHistory(state, historyTag) {
    console.log("push: " + historyTag);
    state.history.push(historyTag);
  },
  clearHistory(state) {
    state.history = [];
  },

  // All the update functions: one generic one to be able to create new fields
  updateDoc(state, keyValuePair) {
    // had to add the comparision with 0 in order to be able to store 0s
    if (
      !keyValuePair ||
      !keyValuePair.key ||
      (keyValuePair.value !== 0 && !keyValuePair.value)
    )
      return;

    state.document[keyValuePair.key] = keyValuePair.value;
  },

  updateBuy(state, keyValuePair) {
    // had to add the comparision with 0 in order to be able to store 0s
    if (
      !keyValuePair ||
      !keyValuePair.key ||
      (keyValuePair.value !== 0 && !keyValuePair.value)
    )
      return;

    console.log("state: updateBuy");
    state.buy[keyValuePair.key] = keyValuePair.value;
  },

  finishProcess(state) {
    state.mode = C.MODE.DEFAULT;
    state.document = {};
    state.biens = [];
  },

  //Steps
  setBiens(state, biens) {
    state.biens = biens;
  },

  // Contrat
  startAddContrat(state) {
    state.mode = C.MODE.ADD;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
