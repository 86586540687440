const state = {
  username: "",
  loggedIn: false,
  admin: false,
};
const getters = {
  getName: (state) => {
    return state.username;
  },
  getAuth: (state) => {
    return state.loggedIn;
  },
};
const actions = {};
const mutations = {
  setUsername(state, name) {
    state.username = name;
  },
  login(state) {
    state.loggedIn = true;
  },
  loginAdmin(state) {
    state.loggedIn = true;
    state.admin = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
