import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import store from "./store/store.js";
import router from "./router";
import firebase from "firebase";
import db from "./firebase/firebaseInit";
import axios from "axios";
import "./assets/css/navbar.scss";
import "./assets/css/talkii.css";
import "./assets/css/main.css";
import "./assets/css/platform.scss";
import "./assets/css/footer.css";
import "./assets/css/switch.scss";
import "./assets/css/dialog.css";
Vue.config.productionTip = false;
Vue.use(BootstrapVue, axios);

firebase.auth().onAuthStateChanged(() => {
  db;
  // if (!app) {
  new Vue({
    store,
    router,
    render: (h) => h(App),
  }).$mount("#app");
  // }
});
