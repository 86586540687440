<template>
  <div>
    <Navbar></Navbar>
    <div class="wrapper">
      <!-- section prices -->
      <section
        id="login"
        v-bind:class="{ 'bg-blue': !isAdmin, 'bg-orange': isAdmin }"
        class="section jpadding jpadding-20 section-unfixed bg-blue"
      >
        <!-- 
            <div class="illustration">
                <img src="/img/vectors/talkii-illustration2.svg" alt="">
            </div> -->

        <div class="jcard jcard-main jcard-login jcard-nohover">
          <div class="brand mb-3">
            <img
              src="@/assets/logos/talkii-logo-gradient.png"
              alt="Talkii Logo"
            />
          </div>
          <div class="sub-title text-center">
            <h2 v-if="!isAdmin">Admin</h2>
            <h2 v-else>Admin</h2>
          </div>

          <div class="login">
            <div v-if="!loading">
              <div class="form-group">
                <label for="email"><i class="fa fa-user"></i> Email</label>
                <input type="text" class="form-control" v-model="email" />
              </div>

              <div class="form-group">
                <label for="password"
                  ><i class="fa fa-lock"></i> Password</label
                >
                <input
                  type="password"
                  class="form-control"
                  v-model="password"
                />
              </div>

              <div v-if="!isAdmin" class="btn-container text-center mt-4 mb-4">
                <button class="jbtn jbtn-blue" @click="login">Let's go!</button>
              </div>

              <div v-else class="btn-container text-center mt-4 mb-4">
                <button class="jbtn jbtn-blue" @click="loginAdmin">
                  Admin Login
                </button>
              </div>
            </div>
            <div v-if="loading" class="text-center loading-container">
              <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
            </div>

            <b-alert v-model="showAlert" class="login-message" variant="danger"
              ><i class="fal fa-exclamation-triangle"></i>
              {{ loginMsg }}</b-alert
            >

            <div class="bottom-links text-center">
              <a href="">Forgot password?</a>
              <!-- <router-link :to="{ name: 'Plans' }"
                ><p><a>Create an account</a></p></router-link
              > -->
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
import db from "@/firebase/firebaseInit";
import Navbar from "@/components/Navbar";

export default {
  name: "Login",
  components: {
    Navbar: Navbar,
  },
  data() {
    return {
      loginTitle: "Bonjour",
      loading: false,
      showAlert: false,
      email: "",
      password: "",
      loginMsg: "",
      currentUser: firebase.auth().currentUser,

      isAdmin: false,
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.showAlert = false;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$store.commit(
            "setUsername",
            firebase.auth().currentUser.displayName
          );
          this.$store.commit("login");
          this.loginMsg = "SUCCESS";
          this.$router.replace("AdminDashboard");
        })
        .catch((err) => {
          this.loginMsg = err.message;
          this.showAlert = true;
          this.loading = false;
        });
    },
    loginAdmin() {
      this.loading = true;
      this.showAlert = false;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$store.commit(
            "setUsername",
            firebase.auth().currentUser.displayName
          );
          //load user doc to check if admin
          var docRef = db
            .collection("users")
            .doc(firebase.auth().currentUser.uid);
          docRef.onSnapshot((doc) => {
            var isAdmin = doc.data().admin;
            console.log("User is Admin: ", isAdmin);
            this.$store.commit("setUser", doc.data());

            this.$store.commit("loginAdmin");
            this.loginMsg = "SUCCESS";
            this.$router.push({ name: "AdminDashboard" });
          });
        })
        .catch((err) => {
          this.loginMsg = err.message;
          this.showAlert = true;
          this.loading = false;
        });
    },
    setIsAdmin() {
      if (
        this.$route.params.type != null &&
        this.$route.params.type === "admin"
      ) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    // just use `this`
    if (to.params.type != null && to.params.type === "admin") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
    next();
  },
  created() {
    this.setIsAdmin();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped></style>
